import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CmsService } from 'src/app/cms/cms.service';
import { ContentPage } from 'src/app/cms/model';

@Component({
    selector: 'kc-content-page',
    templateUrl: './content-page.component.html',
    styleUrls: ['./content-page.component.css']
})
export class ContentPageComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private cms: CmsService
    ) {}

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            const page: ContentPage = data['page'];
            this.pageTitle = page.title;
            const pictureHash = page?.picture?.private_hash;
            if (pictureHash) {
                this.picture = this.cms.getAssetUrl(pictureHash);
            }
            this.text = page.text;
        });
    }

    public pageTitle?: string;

    public picture?: string;

    public text?: string;
}
