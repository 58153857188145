import { DateTime } from 'luxon';

export interface Asset {
    private_hash: string;
}

export interface NavBarLink {
    title: string;
    key: string;
}

type PageLink = 'key' | 'title' | 'nav_text';

export interface NavPage {
    key: string;
    title: string;
    nav_text: string;
    picture: Asset;
    sub_nav_pages: Pick<NavPage, PageLink>[];
    sub_pages: Pick<ContentPage, PageLink>[];
}

export interface ContentPage {
    key: string;
    title: string;
    nav_text: string;
    picture?: Asset;
    text: string;
}

export interface OpeningHour {
    day: string;
    time: string;
}

export interface TeamMember {
    first_name: string;
    last_name: string;
    phone?: string;
    email?: string;
}

export interface Author {
    first_name: string;
    last_name: string;
    website?: string;
    type: 'webdesign' | 'photography';
}

export interface ContactPage {
    title: string;
    street: string;
    zip: string;
    city: string;
    map: Asset;
    map_link: string;
    opening_hours: OpeningHour[];
    opening_hours_comment: string;
    corona: string;
    team: TeamMember[];
    authors: Author[];
}

export interface EventSeries {
    slug: string;
    title: string;
    type: string;
    picture?: Asset;
    description?: string;
}

export interface Event {
    slug: string;
    title?: string;
    date: string;
    time: string;
    dateTime: DateTime;
    picture?: Asset;
    description?: string;
    series?: EventSeries;
    linkComponents: string[];
}

export type CmsEvent = Omit<Event, 'dateTime' | 'linkComponents'>;

export function eventFromCms(event: CmsEvent): Event {
    const dateTime = DateTime.fromSQL(`${event.date} ${event.time}`);
    const linkComponents = [
        dateTime.toFormat('yyyy'),
        dateTime.toFormat('MM'),
        dateTime.toFormat('dd'),
        dateTime.toFormat('HHmm'),
        event.slug
    ];
    return {
        ...event,
        dateTime,
        linkComponents
    };
}

export interface NewsPost {
    slug: string;
    title: string;
    publication_date: string;
    dateTime: DateTime;
    nav_text?: string;
    picture?: Asset;
    content?: string;
    linkComponents: string[];
}

export type CmsNewsPost = Omit<NewsPost, 'dateTime'>;

export function newsPostFromCms(news: CmsNewsPost): NewsPost {
    const dateTime = DateTime.fromSQL(news.publication_date);
    const linkComponents = [
        dateTime.toFormat('yyyy'),
        dateTime.toFormat('MM'),
        dateTime.toFormat('dd'),
        news.slug
    ];
    return {
        ...news,
        dateTime,
        linkComponents
    };
}
