import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

import { CmsService } from 'src/app/cms/cms.service';
import { NavPage } from 'src/app/cms/model';

@Component({
    selector: 'kc-nav-page',
    templateUrl: './nav-page.component.html',
    styleUrls: ['./nav-page.component.css']
})
export class NavPageComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private cms: CmsService
    ) {}

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            const navPage: NavPage = data['navPage'];
            this.navPicture = this.cms.getAssetUrl(
                navPage.picture.private_hash
            );
        });
    }

    public navPicture?: string;

    public get hasContent(): boolean {
        const primaryChildren = this.route.children.filter(
            e => e.outlet == 'primary'
        );
        return primaryChildren.length > 0;
    }

    @HostBinding('class')
    public hostClasses = `
    md:block
    col-start-1 md:col-start-2
    row-start-3 md:row-start-1 md:row-end-[span_4]
    px-6 sm:px-8 md:px-0
    overflow-y-auto
  `;

    @HostBinding('class.block')
    public get isBlock(): boolean {
        return this.hasContent;
    }

    @HostBinding('class.hidden')
    public get isHidden(): boolean {
        return !this.hasContent;
    }
}

export const NAVPAGE_ROUTE: Route = {
    path: '',
    component: NavPageComponent
};
