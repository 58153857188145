import { Component, HostBinding } from '@angular/core';
import {
    NavigationCancel,
    ResolveEnd,
    ResolveStart,
    Router
} from '@angular/router';

import { concat, filter, map, of } from 'rxjs';

import { CmsService } from './cms/cms.service';

@Component({
    selector: 'kc-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(
        private router: Router,
        private cms: CmsService
    ) {}

    public isLoading = concat(of(false), concat(this.router.events)).pipe(
        filter(
            e =>
                e instanceof ResolveStart ||
                e instanceof ResolveEnd ||
                e instanceof NavigationCancel
        ),
        map(e => (e instanceof ResolveStart ? true : false))
    );

    public navBarLinks = this.cms.getNavBarLinks();

    public footerTitle = this.cms.getContactTitle();

    @HostBinding('class')
    public hostClasses = `
      grid grid-rows-[auto_auto_1fr_auto] grid-cols-[min-content]
      auto-cols-[0] md:auto-cols-[1fr]
      justify-center justify-items-stretch items-stretch
      min-h-screen md:h-screen
      bg-white
      font-serif
    `;
}
