import { Pipe, PipeTransform } from '@angular/core';

import { DateTime } from 'luxon';

@Pipe({
    name: 'datetime'
})
export class DateTimePipe implements PipeTransform {
    public transform(
        value: DateTime,
        format?: string,
        tweaks?: object
    ): string {
        let formatOpts;
        switch (format) {
            case 'shortDate':
                formatOpts = DateTime.DATE_SHORT;
                break;
            case 'medDate':
                formatOpts = DateTime.DATE_MED;
                break;
            case 'medDateWithWeekday':
                formatOpts = DateTime.DATE_MED_WITH_WEEKDAY;
                break;
            case 'fullDate':
                formatOpts = DateTime.DATE_FULL;
                break;
            case 'hugeDate':
                formatOpts = DateTime.DATE_HUGE;
                break;
            case 'simpleTime':
                formatOpts = DateTime.TIME_SIMPLE;
                break;
            case 'timeWithSeconds':
                formatOpts = DateTime.TIME_WITH_SECONDS;
                break;
            case 'timeWithShortOffset':
                formatOpts = DateTime.TIME_WITH_SHORT_OFFSET;
                break;
            case 'timeWithLongOffset':
                formatOpts = DateTime.TIME_WITH_LONG_OFFSET;
                break;
            case 'simple24Time':
                formatOpts = DateTime.TIME_24_SIMPLE;
                break;
            case '24TimeWithSeconds':
                formatOpts = DateTime.TIME_24_WITH_SECONDS;
                break;
            case '24TimeWithShortOffset':
                formatOpts = DateTime.TIME_24_WITH_SHORT_OFFSET;
                break;
            case '24TimeWithLongOffset':
                formatOpts = DateTime.TIME_24_WITH_LONG_OFFSET;
                break;
            case 'shortDateTime':
                formatOpts = DateTime.DATETIME_SHORT;
                break;
            case 'medDateTime':
                formatOpts = DateTime.DATETIME_MED;
                break;
            case 'fullDateTime':
                formatOpts = DateTime.DATETIME_FULL;
                break;
            case 'hugeDateTime':
                formatOpts = DateTime.DATETIME_HUGE;
                break;
            case 'shortDateTimeWithSeconds':
                formatOpts = DateTime.DATETIME_SHORT_WITH_SECONDS;
                break;
            case 'medDateTimeWithSeconds':
                formatOpts = DateTime.DATETIME_MED_WITH_SECONDS;
                break;
            case 'fullDateTimeWithSeconds':
                formatOpts = DateTime.DATETIME_FULL_WITH_SECONDS;
                break;
            case 'hugeDateTimeWithSeconds':
                formatOpts = DateTime.DATETIME_HUGE_WITH_SECONDS;
                break;
            case 'medDateTimeWithWeekday':
                formatOpts = DateTime.DATETIME_MED_WITH_WEEKDAY;
                break;
            default:
                formatOpts = {};
        }

        if (tweaks) {
            formatOpts = { ...formatOpts, ...tweaks };
        }

        return value.toLocaleString(formatOpts);
    }
}
