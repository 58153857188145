import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

import { NavPage } from 'src/app/cms/model';

import { NavPageComponent } from '../nav-page/nav-page.component';

export interface NavItem {
    link: string | string[];
    title: string;
    subtitle?: string;
    text?: string;
}

@Component({
    selector: 'kc-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            const titlePath: string[] | undefined = data['sidebarTitlePath'];
            const textPath: string[] | undefined = data['sidebarTextPath'];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const title: string | null = titlePath?.reduce<any>(
                (obj, prop) => (obj ? obj[prop] : null),
                data
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const text: string | null = textPath?.reduce<any>(
                (obj, prop) => (obj ? obj[prop] : null),
                data
            );

            const navPage: NavPage | undefined = data['navPage'];

            const navItems: NavItem[] = data['navItems'] || [];

            this.title = title || navPage?.title;
            this.text = text || navPage?.nav_text;

            const subNavItems =
                navPage?.sub_nav_pages.map(({ key, title, nav_text }) => {
                    return {
                        link: key,
                        title: title,
                        text: nav_text
                    };
                }) || [];
            const subPageItems =
                navPage?.sub_pages.map(({ key, title, nav_text }) => {
                    return {
                        link: key,
                        title: title,
                        text: nav_text
                    };
                }) || [];
            this.navItems = [...subNavItems, ...subPageItems, ...navItems];
        });
    }

    public title?: string;

    public text?: string;

    public navItems: NavItem[] = [];

    public get primaryRoute(): ActivatedRoute | undefined {
        const primaryChildren = this.route.parent?.children.filter(
            e => e.outlet == 'primary'
        );
        return primaryChildren ? primaryChildren[0] : undefined;
    }

    public get hasPrimaryContent(): boolean {
        const primaryChildren = this.route.parent?.children.filter(
            e => e.outlet == 'primary'
        );

        if (primaryChildren == null || primaryChildren.length == 0) {
            return false;
        }

        if (primaryChildren[0].component?.name != NavPageComponent.name) {
            return true;
        }

        const contentChildren = primaryChildren[0].children.filter(
            e => e.outlet == 'primary'
        );
        return contentChildren.length > 0;
    }

    @HostBinding('class')
    public hostClasses = `
    col-start-1
    row-start-2 md:row-end-[span_2]
    px-4
    md:bg-black md:text-white
    flex flex-col justify-start
  `;

    @HostBinding('class.row-end-[span_2]')
    public get spansRows(): boolean {
        return !this.hasPrimaryContent;
    }
}

export const SIDEBAR_ROUTE: Route = {
    path: '',
    component: SidebarComponent,
    outlet: 'sidebar'
};
