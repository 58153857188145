import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'kc-text-content',
    templateUrl: './text-content.component.html',
    styleUrls: ['./text-content.component.css']
})
export class TextContentComponent {
    @Input()
    public title?: string;

    @Input()
    public subtitle?: string;

    @Input()
    public hideTitle?: boolean;

    @HostBinding('class')
    public hostClasses = `
    min-h-full
    flex flex-col
    justify-start align-center
    bg-white
  `;
}
