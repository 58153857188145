import { Component, Input } from '@angular/core';

@Component({
    selector: 'kc-cmstext',
    templateUrl: './cmstext.component.html',
    styleUrls: ['./cmstext.component.css']
})
export class CMSTextComponent {
    @Input()
    public text?: string;
}
