import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgIconsModule } from '@ng-icons/core';
import { heroChevronDoubleRight } from '@ng-icons/heroicons/outline';

import { CMSTextComponent } from './cmstext/cmstext.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { DateTimePipe } from './date-time.pipe';
import { FooterComponent } from './footer/footer.component';
import { NavPageComponent } from './nav-page/nav-page.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OpeningHoursComponent } from './opening-hours/opening-hours.component';
import { PictureContentComponent } from './picture-content/picture-content.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TextContentComponent } from './text-content/text-content.component';

@NgModule({
    declarations: [
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        NavPageComponent,
        PictureContentComponent,
        TextContentComponent,
        ContentPageComponent,
        CMSTextComponent,
        OpeningHoursComponent,
        DateTimePipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgIconsModule.withIcons({ heroChevronDoubleRight })
    ],
    exports: [
        CommonModule,
        RouterModule,
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        NavPageComponent,
        PictureContentComponent,
        TextContentComponent,
        ContentPageComponent,
        CMSTextComponent,
        OpeningHoursComponent,
        DateTimePipe
    ]
})
export class SharedModule {}
