import { Component, Input } from '@angular/core';

import { NavBarLink } from 'src/app/cms/model';

@Component({
    selector: 'kc-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
    @Input()
    public links: NavBarLink[] = [];
}
