<h2
    *ngIf="subtitle"
    [class.hidden]="hideTitle"
    class="md:block w-full md:w-2/3 md:max-w-4xl md:mt-16 md:mx-auto pb-0 text-2xl text-medium md:text-center"
>
    {{ subtitle }}
</h2>
<h1
    [class.hidden]="hideTitle"
    class="md:block w-full md:w-2/3 md:max-w-4xl md:mx-auto mb-6 md:first:mt-16 pb-0 text-2xl font-semibold md:text-center"
>
    {{ title }}
</h1>
<div class="w-full md:w-2/3 md:max-w-4xl md:mx-auto pb-2 md:pb-6">
    <ng-content></ng-content>
</div>
