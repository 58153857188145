<nav>
    <h1
        class="mb-2 px-2 sm:px-4 font-semibold text-5xl sm:text-6xl md:text-5xl lg:text-6xl"
    >
        <a routerLink="/">Kulturcafé</a>
    </h1>
    <ul
        class="min-h-[2.2rem] flex flex-row justify-between mb-4 px-2 sm:px-4 py-1 border-solid border-current border-t border-b font-mono"
    >
        <li
            *ngFor="let link of links"
            class="first:ml-0 ml-2"
        >
            <a [routerLink]="link.key">{{ link.title }}</a>
        </li>
    </ul>
</nav>
