<div
    class="flex flex-row justify-between mb-4 px-2 sm:px-4 py-1 border-solid border-current border-b font-mono text-xl"
>
    <a routerLink="./">{{ title }}</a>
</div>
<div
    [class.block]="!hasPrimaryContent"
    [class.hidden]="hasPrimaryContent"
    class="flex-grow md:block w-full px-2 sm:px-4 overflow-y-auto"
>
    <div class="flex flex-col justify-start min-h-full pb-2">
        <p
            *ngIf="text"
            class="first:mt-0 mt-8"
        >
            {{ text }}
        </p>
        <ul
            *ngIf="navItems.length > 0"
            class="first:mt-0 mt-8"
        >
            <li
                *ngFor="let item of navItems"
                class="first:mt-0 mt-6"
            >
                <a
                    [routerLink]="item.link"
                    [relativeTo]="primaryRoute"
                >
                    <span
                        *ngIf="item.subtitle"
                        class="block"
                        >{{ item.subtitle }}</span
                    >
                    <span
                        [class]="{
                            'font-bold': !item.subtitle,
                            'font-semibold': item.subtitle
                        }"
                        class="block whitespace-nowrap"
                        ><span class="whitespace-normal">{{ item.title }}</span
                        >&nbsp;<ng-icon
                            class="align-text-bottom"
                            name="heroChevronDoubleRight"
                        ></ng-icon>
                    </span>
                    <span
                        *ngIf="item.text"
                        class="block"
                        >{{ item.text }}</span
                    >
                </a>
            </li>
        </ul>
        <router-outlet class="first:mb-0 mb-8 last:mb-0"></router-outlet>
    </div>
</div>
