import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, ResolveFn } from '@angular/router';

import { EMPTY, mergeMap, Observable, of } from 'rxjs';

import { CmsService } from '../cms/cms.service';
import { NavPage } from '../cms/model';

export const navPageResolver: ResolveFn<NavPage> = (
    route: ActivatedRouteSnapshot
): Observable<NavPage> => {
    const router = inject(Router);
    const cms = inject(CmsService);

    return of<string>(route.data['navPageKey']).pipe(
        mergeMap(key => {
            if (key) {
                return cms.getNavPageByKey(key);
            } else {
                return of(null);
            }
        }),
        mergeMap(navPage => {
            if (navPage) {
                return of(navPage);
            } else {
                router.navigate(['/404']);
                return EMPTY;
            }
        })
    );
};
