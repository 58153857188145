import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { navPageResolver } from './cms/navpage.resolver';
import { NAVPAGE_ROUTE } from './shared/nav-page/nav-page.component';
import { SIDEBAR_ROUTE } from './shared/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home'
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'culture',
        loadChildren: () =>
            import('./culture/culture.module').then(m => m.CultureModule)
    },
    {
        path: 'cafe',
        loadChildren: () => import('./cafe/cafe.module').then(m => m.CafeModule)
    },
    {
        path: 'contact',
        loadChildren: () =>
            import('./contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'room',
        loadChildren: () => import('./room/room.module').then(m => m.RoomModule)
    },
    {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
    },
    {
        path: '**',
        data: {
            navPageKey: 'error'
        },
        resolve: {
            navPage: navPageResolver
        },
        children: [SIDEBAR_ROUTE, NAVPAGE_ROUTE]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
