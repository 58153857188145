<kc-navbar
    class="px-4 pt-4 md:bg-black md:text-white"
    [links]="(navBarLinks | async) || []"
></kc-navbar>
<div
    *ngIf="isLoading | async"
    class="col-start-1 row-start-2 row-end-[span_2] bg-white/30 md:bg-black/20 backdrop-blur z-20"
></div>
<router-outlet
    name="sidebar"
    class="col-start-1 row-start-2 md:row-end-[span_2] md:bg-black"
></router-outlet>
<div
    *ngIf="isLoading | async"
    class="hidden md:block md:col-start-2 md:row-start-1 md:row-end-[span_4] backdrop-blur md:bg-black/60 z-20"
></div>
<router-outlet
    class="hidden md:block md:col-start-2 md:row-start-1 md:row-end-[span_4] md:bg-gray-900"
></router-outlet>
<kc-footer
    class="col-start-1 row-start-4 px-4 pt-4 pb-6 md:bg-black md:text-white"
    [title]="(footerTitle | async) || ''"
></kc-footer>
