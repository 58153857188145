import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'kc-picture-content',
    templateUrl: './picture-content.component.html',
    styleUrls: ['./picture-content.component.css']
})
export class PictureContentComponent {
    @Input()
    public picture?: string;

    @HostBinding('class')
    public hostClasses = 'block h-full bg-center bg-cover';

    @HostBinding('style.background-image')
    public get pictureBackground(): string | undefined {
        if (this.picture) {
            return `url('${this.picture}')`;
        } else {
            return undefined;
        }
    }
}
