import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { catchError, from, map, Observable, of } from 'rxjs';

import { SDK as DirectusSDK } from '@directus/sdk-js';
import { DateTime } from 'luxon';

import {
    CmsEvent,
    CmsNewsPost,
    ContactPage,
    ContentPage,
    Event,
    eventFromCms,
    EventSeries,
    NavBarLink,
    NavPage,
    NewsPost,
    newsPostFromCms
} from './model';

export const CMS_URL = new InjectionToken<string>('cms.url');
export const CMS_PROJECT = new InjectionToken<string>('cms.project');
export const CMS_AUTH_TOKEN = new InjectionToken<string>('cms.auth-token');

@Injectable({
    providedIn: 'root'
})
export class CmsService {
    constructor(
        @Inject(CMS_URL) url: string,
        @Inject(CMS_PROJECT) project: string,
        @Inject(CMS_AUTH_TOKEN) @Optional() authToken?: string
    ) {
        this.directus = new DirectusSDK({
            url: url,
            project: project,
            token: authToken,
            mode: 'cookie'
        });
    }

    private directus;

    public getAssetUrl(hash: string): string {
        return this.directus.getAssetUrl(hash);
    }

    public getNavBarLinks(): Observable<NavBarLink[]> {
        return from(
            this.directus.getItems<NavBarLink[]>('nav_pages', {
                filter: {
                    super_page: { null: true },
                    key: {
                        nin: ['home', 'error']
                    }
                },
                fields: ['title', 'key']
            })
        ).pipe(
            map(value => value.data),
            catchError(() => of([]))
        );
    }

    public getContactTitle(): Observable<string> {
        return from(
            this.directus.getItem<ContactPage>('contact_page', 1, {
                fields: ['title']
            })
        ).pipe(
            map(value => value.data.title),
            catchError(() => of(''))
        );
    }

    public getNavPageByKey(key: string): Observable<NavPage | null> {
        return from(
            this.directus.getItems<NavPage[]>('nav_pages', {
                filter: {
                    key: { eq: key }
                },
                fields: [
                    'key',
                    'title',
                    'nav_text',
                    'sub_nav_pages.key',
                    'sub_nav_pages.title',
                    'sub_nav_pages.nav_text',
                    'sub_pages.key',
                    'sub_pages.title',
                    'sub_pages.nav_text',
                    'picture.private_hash'
                ]
            })
        ).pipe(
            map(value => value.data),
            map(navPages => (navPages.length > 0 ? navPages[0] : null)),
            catchError(() => of(null))
        );
    }

    public getPageByKey(key: string): Observable<ContentPage | null> {
        return from(
            this.directus.getItems<ContentPage[]>('pages', {
                filter: {
                    key: { eq: key }
                },
                fields: ['*', 'picture.private_hash'],
                limit: 1
            })
        ).pipe(
            map(value => value.data),
            map(pages => (pages.length > 0 ? pages[0] : null)),
            catchError(() => of(null))
        );
    }

    public getContactPage(): Observable<ContactPage | null> {
        return from(
            this.directus.getItem<ContactPage>('contact_page', 1, {
                fields: ['*', 'map.private_hash']
            })
        ).pipe(
            map(value => value.data),
            catchError(() => of(null))
        );
    }

    public getCurrentEventSeriesWithType(
        type: string
    ): Observable<EventSeries[]> {
        return from(
            this.directus.getItems<EventSeries[]>('eventseries', {
                filter: {
                    type: { eq: type },
                    status: { eq: 'current' }
                },
                sort: 'title',
                fields: ['slug', 'title', 'type']
            })
        ).pipe(
            map(value => value.data),
            catchError(() => of([]))
        );
    }

    public getEventSeriesBySlug(slug: string): Observable<EventSeries | null> {
        return from(
            this.directus.getItems<EventSeries[]>('eventseries', {
                filter: {
                    slug: { eq: slug }
                },
                fields: [
                    'slug',
                    'title',
                    'type',
                    'description',
                    'picture.private_hash'
                ],
                limit: 1
            })
        ).pipe(
            map(value => value.data),
            map(series => (series.length > 0 ? series[0] : null)),
            catchError(() => of(null))
        );
    }

    public getEventsOfSeries(series: EventSeries): Observable<Event[]> {
        return from(
            this.directus.getItems<Event[]>('events', {
                filter: {
                    'series.slug': { eq: series.slug }
                },
                sort: 'date,time',
                fields: ['slug', 'title', 'date', 'time']
            })
        ).pipe(
            map(value => value.data),
            map(events => events.map(eventFromCms)),
            catchError(() => of([]))
        );
    }

    public getNextEventOfSeries(series: EventSeries): Observable<Event | null> {
        const now = DateTime.now().startOf('day');
        return from(
            this.directus.getItems<Event[]>('events', {
                filter: {
                    'series.slug': { eq: series.slug },
                    'date': { gte: now.toISODate() },
                    'time': {
                        gte: now.toISOTime({
                            includeOffset: false,
                            suppressSeconds: true
                        })
                    }
                },
                sort: 'date,time',
                fields: ['slug', 'title', 'date', 'time'],
                limit: 1
            })
        ).pipe(
            map(value => value.data),
            map(events => events.map(eventFromCms)),
            map(events => (events.length > 0 ? events[0] : null)),
            catchError(() => of(null))
        );
    }

    public getEventsBeforeDate(date: DateTime): Observable<Event[]> {
        return from(
            this.directus.getItems<CmsEvent[]>('events', {
                filter: {
                    date: { lt: date.toISODate() },
                    time: {
                        lt: date.toISOTime({
                            includeOffset: false,
                            suppressSeconds: true
                        })
                    }
                },
                sort: '-date,-time',
                fields: [
                    'slug',
                    'title',
                    'date',
                    'time',
                    'series.title',
                    'series.slug'
                ]
            })
        ).pipe(
            map(value => value.data),
            map(events => events.map(eventFromCms)),
            catchError(() => of([]))
        );
    }

    public getEventsAfterDate(date: DateTime): Observable<Event[]> {
        return from(
            this.directus.getItems<CmsEvent[]>('events', {
                filter: {
                    date: { gte: date.toISODate() },
                    time: {
                        gte: date.toISOTime({
                            includeOffset: false,
                            suppressSeconds: true
                        })
                    }
                },
                sort: 'date,time',
                fields: [
                    'slug',
                    'title',
                    'date',
                    'time',
                    'series.title',
                    'series.slug'
                ]
            })
        ).pipe(
            map(value => value.data),
            map(events => events.map(eventFromCms)),
            catchError(() => of([]))
        );
    }

    public getEventByDateAndSlug(
        date: DateTime,
        slug: string
    ): Observable<Event | null> {
        return from(
            this.directus.getItems<CmsEvent[]>('events', {
                filter: {
                    date: { eq: date.toISODate() },
                    time: {
                        eq: date.toISOTime({
                            includeOffset: false,
                            suppressSeconds: true
                        })
                    },
                    slug: { eq: slug }
                },
                fields: [
                    'slug',
                    'title',
                    'date',
                    'time',
                    'description',
                    'picture.private_hash',
                    'series.slug',
                    'series.title',
                    'series.type',
                    'series.description',
                    'series.picture.private_hash'
                ],
                limit: 1
            })
        ).pipe(
            map(value => value.data),
            map(events => events.map(eventFromCms)),
            map(events => (events.length > 0 ? events[0] : null)),
            catchError(() => of(null))
        );
    }

    public getNews(): Observable<NewsPost[]> {
        return from(
            this.directus.getItems<CmsNewsPost[]>('news', {
                filter: {
                    publication_date: { lte: DateTime.now().toISODate() }
                },
                fields: ['slug', 'title', 'publication_date', 'nav_text'],
                sort: '-publication_date'
            })
        ).pipe(
            map(value => value.data),
            map(news => news.map(newsPostFromCms)),
            catchError(() => of([]))
        );
    }

    public getNewsHighlight(): Observable<NewsPost | null> {
        return from(
            this.directus.getItems<CmsNewsPost[]>('news', {
                filter: {
                    publication_date: { lte: DateTime.now().toISODate() }
                },
                fields: ['slug', 'title', 'publication_date', 'nav_text'],
                sort: '-sticky,-publication_date',
                limit: 1
            })
        ).pipe(
            map(value => value.data),
            map(news => news.map(newsPostFromCms)),
            map(news => (news.length > 0 ? news[0] : null)),
            catchError(() => of(null))
        );
    }

    public getNewsPostByDateAndSlug(
        date: DateTime,
        slug: string
    ): Observable<NewsPost | null> {
        return from(
            this.directus.getItems<CmsNewsPost[]>('news', {
                filter: {
                    publication_date: { eq: date.toISODate() },
                    slug: { eq: slug }
                },
                fields: [
                    'slug',
                    'title',
                    'publication_date',
                    'content',
                    'picture.private_hash'
                ],
                limit: 1
            })
        ).pipe(
            map(value => value.data),
            map(news => news.map(newsPostFromCms)),
            map(news => (news.length > 0 ? news[0] : null)),
            catchError(() => of(null))
        );
    }
}
