import { Component, Input } from '@angular/core';

@Component({
    selector: 'kc-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {
    @Input()
    public title?: string;
}
