import { Component, Input } from '@angular/core';

import { OpeningHour } from 'src/app/cms/model';

@Component({
    selector: 'kc-opening-hours',
    templateUrl: './opening-hours.component.html',
    styleUrls: ['./opening-hours.component.css']
})
export class OpeningHoursComponent {
    @Input()
    public openingHours: OpeningHour[] = [];

    @Input()
    public comment?: string;
}
