<h2 class="font-bold text-lg">Öffnungszeiten</h2>
<table
    *ngIf="openingHours.length > 0"
    class="table-auto w-full"
>
    <tr *ngFor="let item of openingHours">
        <td>{{ item.day }}</td>
        <td>{{ item.time }}</td>
    </tr>
</table>
<p *ngIf="comment">{{ comment }}</p>
